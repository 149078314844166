<template>
    <div class="h-full">
        <div class="drawer-header d-flex align-center mb-4">
            <span class="font-weight-semibold text-base text--primary"
                >{{ eventLocal.id ? 'Editar' : 'Criar' }} Evento</span
            >
            <v-spacer></v-spacer>
            <v-btn
                v-if="eventLocal.id"
                icon
                small
                class="me-1"
                @click="
                    $emit('remove-event');
                    $emit('close-drawer');
                ">
                <v-icon size="22">
                    {{ icons.mdiTrashCanOutline }}
                </v-icon>
            </v-btn>
            <v-btn icon small @click="$emit('close-drawer')">
                <v-icon size="22">
                    {{ icons.mdiClose }}
                </v-icon>
            </v-btn>
        </div>
        <perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-calendar-event-handler">
            <v-form ref="refCalendarEventHandlerForm" class="px-5 pt-3 pb-10" @submit.prevent="handleFormSubmit">
                <v-text-field
                    v-model="eventLocal.name"
                    label="Nome"
                    placeholder="Nome"
                    outlined
                    :rules="[validators.required]"
                    hide-details="auto"
                    dense
                    class="mb-6"></v-text-field>

                <v-select
                    v-model="eventLocal.extendedProps.calendar"
                    outlined
                    label="Calendário"
                    placeholder="Calendário"
                    :item-text="(item) => item.label"
                    :item-value="(item) => item.value"
                    :items="$store.state['app-calendar'].calendarOptions"
                    :rules="[validators.required]"
                    hide-details="auto"
                    :menu-props="{ offsetY: true }"
                    dense
                    class="mb-6">
                    <!-- Selected Item Slot -->
                    <template #selection="{ item }">
                        <div class="d-flex align-center">
                            <v-badge inline :color="item.color" dot class="me-2"></v-badge>
                            <span>{{ item.label }}</span>
                        </div>
                    </template>

                    <!-- Options Slot -->
                    <template #item="{ item }">
                        <div class="d-flex align-center">
                            <v-badge inline :color="item.color" dot class="me-2 mb-1"></v-badge>
                            <span>{{ item.label }}</span>
                        </div>
                    </template>
                </v-select>

                <v-checkbox v-model="allDay" label="Todo o dia"></v-checkbox>

                <v-menu
                    v-model="isStartDateMenuOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    eager
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="eventLocal.start"
                            label="Data Início"
                            readonly
                            outlined
                            dense
                            class="mb-6"
                            hide-details="auto"
                            v-bind="attrs"
                            :rules="[validators.required]"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="eventLocal.start" @input="isStartDateMenuOpen = false"></v-date-picker>
                </v-menu>

                <v-menu
                    v-model="isEndDateMenuOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="eventLocal.end"
                            label="Data Fim"
                            readonly
                            outlined
                            dense
                            class="mb-6"
                            hide-details="auto"
                            v-bind="attrs"
                            :rules="[validators.required, validators.endDateValidator]"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="eventLocal.end" @input="isEndDateMenuOpen = false"></v-date-picker>
                </v-menu>

                <v-item-group v-if="!allDay">
                    <v-container grid-list-md pa-0>
                        <v-layout wrap>
                            <v-flex xs5>
                                <v-autocomplete dense outlined label="Hora Início" v-model="endHour" :items="hours">
                                </v-autocomplete>
                            </v-flex>
                            <v-flex shrink align-self-center>
                                <p class="mb-0">:</p>
                            </v-flex>
                            <v-flex xs5>
                                <v-autocomplete dense outlined label="Hora Fim" v-model="endMinute" :items="minutes">
                                </v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-item-group>

                <v-select
                    outlined
                    label="Repetição"
                    placeholder="Repetição"
                    item-text="label"
                    item-value="value"
                    :items="repetition"
                    :rules="[validators.required]"
                    hide-details="auto"
                    :menu-props="{ offsetY: true }"
                    dense
                    class="mb-6">
                </v-select>

                <!-- <v-switch v-model="eventLocal.timed" label="Timed" hide-details class="mt-0 mb-7 d-inline-flex"></v-switch> -->

                <v-text-field
                    v-model="eventLocal.extendedProps.url"
                    type="url"
                    outlined
                    dense
                    class="mb-6"
                    hide-details="auto"
                    label="URL Evento"
                    placeholder="URL Evento"
                    :rules="[validators.urlValidator]"></v-text-field>

                <v-select
                    v-model="eventLocal.extendedProps.guests"
                    outlined
                    chips
                    small-chips
                    deletable-chips
                    multiple
                    dense
                    class="mb-6 select-guest"
                    hide-details="auto"
                    label="Convidar"
                    placeholder="Convidar"
                    :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    :item-text="(guest) => guest.name"
                    :item-value="(guest) => guest.name"
                    :items="guestsOptions">
                    <!-- Options Slot -->
                    <template #item="{ item }">
                        <div class="d-flex align-center">
                            <v-avatar size="25" class="me-2">
                                <v-img :src="item.avatar"></v-img>
                            </v-avatar>
                            <span class="text-sm">{{ item.name }}</span>
                        </div>
                    </template>
                </v-select>

                <v-text-field
                    v-model="eventLocal.extendedProps.location"
                    label="Localização"
                    dense
                    class="mb-6"
                    hide-details="auto"
                    placeholder="Localização"
                    outlined></v-text-field>

                <v-textarea
                    v-model="eventLocal.extendedProps.description"
                    outlined
                    label="Descrição"
                    placeholder="Descrição"></v-textarea>

                <v-row class="mb-6">
                    <v-col cols="1">
                        <v-icon>{{ bellIcon }}</v-icon></v-col
                    >
                    <v-col cols="11" v-if="allDay">
                        <div
                            class="d-flex align-center justify-center mt-1"
                            v-for="(notification, index) in notificationsAllDaySelectedArray"
                            :key="index">
                            <v-select
                                v-model="notificationsAllDaySelected[index]"
                                item-text="label"
                                item-value="value"
                                :items="notificationAllDay"
                                :rules="[validators.required]"
                                hide-details="auto"
                                :menu-props="{ offsetY: true }"
                                class="ml-2 mb-2"
                                dense
                                outlined
                                @change="openDialogNotificationHandler(notificationsAllDaySelected[index], index)">
                            </v-select>
                            <v-icon size="22" class="ml-4" style="cursor: pointer" @click="removeNotification(index)">
                                {{ icons.mdiClose }}
                            </v-icon>
                        </div>
                        <h4 class="ml-2" style="cursor: pointer" @click="addNotificationArrayHandler">
                            Adicionar notificação
                        </h4>
                    </v-col>
                    <v-col cols="11" v-else>
                        <div
                            class="d-flex align-center justify-center mt-1"
                            v-for="(notification, index) in notificationsNotAllDaySelectedArray"
                            :key="index">
                            <v-select
                                v-model="notificationNotAllDaySelected[index]"
                                item-text="label"
                                item-value="value"
                                :items="notificationNotAllDay"
                                :rules="[validators.required]"
                                hide-details="auto"
                                :menu-props="{ offsetY: true }"
                                class="ml-2 mb-2"
                                dense
                                outlined
                                @change="openDialogNotificationHandler(notificationNotAllDaySelected[index], index)">
                            </v-select>
                            <v-icon size="22" class="ml-4" style="cursor: pointer" @click="removeNotification(index)">
                                {{ icons.mdiClose }}
                            </v-icon>
                        </div>

                        <h4 class="ml-2" style="cursor: pointer" @click="addNotificationArrayHandler">
                            Adicionar notificação
                        </h4>
                    </v-col>
                </v-row>

                <v-btn color="primary" class="me-3" type="submit">
                    {{ eventLocal.id ? 'Editar' : 'Criar ' }}
                </v-btn>
                <v-btn outlined @click="resetEventLocal"> Limpar </v-btn>
            </v-form>
        </perfect-scrollbar>
        <DialogNotAllDay :isActive="openDialogNotAllDay" @closeDialog="closeDialogHandler" />
    </div>
</template>

<script>
    import { ref, watch } from '@vue/composition-api';
    import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

    import { required, urlValidator } from '@core/utils/validation';
    import { dateInPast } from '@core/utils';
    import { mdiTrashCanOutline, mdiClose, mdiBell } from '@mdi/js';
    import DialogNotAllDay from './NotAllDayDialog';

    export default {
        components: {
            PerfectScrollbar,
            DialogNotAllDay,
        },
        props: {
            event: {
                type: Object,
                required: true,
            },
            clearEventData: {
                type: Function,
                required: true,
            },
        },

        data() {
            return {
                hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
                minutes: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
                    28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
                    53, 54, 55, 56, 57, 58, 59,
                ],
                repetition: [
                    { value: 'none', label: 'Não se repete' },
                    { value: 'daily', label: 'Todos os dias' },
                    { value: 'weekly', label: 'Semanalmente' },
                    { value: 'monthly', label: 'Mensalmente' },
                    { value: 'yearly', label: 'Anualmente' },
                    { value: 'weekDays', label: 'Todos os dias da semana (de segunda a sexta)' },
                ],
                notificationNotAllDay: [
                    { value: 'fiveMinutes', label: '5 minutos antes' },
                    { value: 'tenMinutes', label: '10 minutos antes' },
                    { value: 'fifteenMinutes', label: '15 minutos antes' },
                    { value: 'thirtyMinutes', label: '30 minutos antes' },
                    { value: 'oneHour', label: '1 hora antes' },
                    { value: 'oneDay', label: '1 dia antes' },
                    { value: 'custom', label: 'Personalizado...' },
                ],
                notificationAllDay: [
                    { value: 'sameDay', label: 'No mesmo dia à(s) 09:00' },
                    { value: 'dayBefore', label: 'O dia antes à(s) 09:00' },
                    { value: 'twoDaysBefore', label: '2 dias antes à(s) 09:00' },
                    { value: 'oneWeekBefore', label: '1 semana antes à(s) 09:00' },
                    { value: 'oneHourBefore', label: '1 hora antes' },
                    { value: 'oneDayBefore', label: '1 dia antes' },
                    { value: 'custom', label: 'Personalizado...' },
                ],
                endHour: null,
                endMinute: null,
                allDay: false,
                notificationsNotAllDaySelectedArray: [0],
                notificationsAllDaySelectedArray: [1],

                notificationNotAllDaySelected: ['fiveMinutes'],
                notificationsAllDaySelected: ['sameDay'],

                openDialogAllDay: false,
                openDialogNotAllDay: false,

                personalizedNotAllDayIndex: null,

                bellIcon: mdiBell,
            };
        },

        methods: {
            addNotificationArrayHandler() {
                if (this.allDay) {
                    this.notificationsAllDaySelectedArray.push(1);
                    this.notificationsAllDaySelected.push('sameDay');
                } else {
                    this.notificationsNotAllDaySelectedArray.push(0);
                    this.notificationNotAllDaySelected.push('fiveMinutes');
                }
                console.log(this.notificationsAllDaySelectedArray);
                console.log(this.notificationsNotAllDaySelectedArray);
            },

            removeNotification(index) {
                if (this.allDay) {
                    this.notificationsAllDaySelectedArray.splice(index, 1);
                    this.notificationsAllDaySelected.splice(index, 1);
                } else {
                    this.notificationsNotAllDaySelectedArray.splice(index, 1);
                    this.notificationNotAllDaySelected.splice(index, 1);
                }
            },

            openDialogNotificationHandler(value, index) {
                if (value == 'custom' && this.allDay) {
                    console.log(value);
                } else if (value == 'custom' && !this.allDay) {
                    this.personalizedNotAllDayIndex = index;

                    this.openDialogNotAllDay = true;
                    console.log(this.openDialogNotAllDay);
                }
            },

            closeDialogHandler() {
                this.openDialogNotAllDay = false;

                this.notificationNotAllDaySelected[this.personalizedNotAllDayIndex] = 'fiveMinutes';
            },
        },

        setup(props, { emit }) {
            // ————————————————————————————————————
            //* ——— Template Refs
            // ————————————————————————————————————

            const refCalendarEventHandlerForm = ref(null);

            // ————————————————————————————————————
            //* ——— Local Event
            // ————————————————————————————————————

            const eventLocal = ref(JSON.parse(JSON.stringify(props.event)));
            const resetEventLocal = () => {
                eventLocal.value = JSON.parse(JSON.stringify(props.event));

                // Only get date from event
                if (eventLocal.value.start)
                    eventLocal.value.start = new Date(eventLocal.value.start).toISOString().substr(0, 10);
                if (eventLocal.value.end)
                    eventLocal.value.end = new Date(eventLocal.value.end).toISOString().substr(0, 10);

                // Reset Validation
                refCalendarEventHandlerForm.value.resetValidation();
            };
            watch(
                () => props.event,
                () => {
                    resetEventLocal();
                },
                { deep: true },
            );

            // ————————————————————————————————————
            //* ——— Form
            // ————————————————————————————————————

            const isStartDateMenuOpen = ref(false);
            const isEndDateMenuOpen = ref(false);

            const guestsOptions = [
                { avatar: require('@/assets/images/avatars/1.png'), name: 'Jane Foster' },
                { avatar: require('@/assets/images/avatars/3.png'), name: 'Donna Frank' },
                { avatar: require('@/assets/images/avatars/5.png'), name: 'Gabrielle Robertson' },
                { avatar: require('@/assets/images/avatars/7.png'), name: 'Lori Spears' },
                { avatar: require('@/assets/images/avatars/6.png'), name: 'Sandy Vega' },
                { avatar: require('@/assets/images/avatars/2.png'), name: 'Cheryl May' },
            ];

            const handleFormSubmit = () => {
                const isFormValid = refCalendarEventHandlerForm.value.validate();

                if (!isFormValid) return;

                const eventData = JSON.parse(JSON.stringify(eventLocal.value));

                // * If event has id => Edit Event
                // Emit event for add/update event
                if (eventData.id) emit('update-event', eventData);
                else emit('add-event', eventData);

                // Close sidebar
                emit('close-drawer');
            };

            // ————————————————————————————————————
            //* ——— Perfect Scrollbar
            // ————————————————————————————————————

            const perfectScrollbarSettings = {
                maxScrollbarLength: 60,
                wheelPropagation: false,
                wheelSpeed: 0.7,
            };

            // ————————————————————————————————————
            //* ——— Form Validator
            // ————————————————————————————————————

            const endDateValidator = (val) => {
                if (!eventLocal.value.start) return true;

                return !dateInPast(new Date(val), new Date(eventLocal.value.start)) || 'End date is in past';
            };

            return {
                // Template Refs
                refCalendarEventHandlerForm,

                // Local Event
                eventLocal,
                resetEventLocal,

                // Form
                isStartDateMenuOpen,
                isEndDateMenuOpen,
                guestsOptions,
                handleFormSubmit,

                // Perfect Scrollbar
                perfectScrollbarSettings,

                // Field Validators
                validators: {
                    required,
                    urlValidator,
                    endDateValidator,
                },

                // Icons
                icons: {
                    mdiTrashCanOutline,
                    mdiClose,
                },
            };
        },
    };
</script>

<style lang="scss">
    .ps-calendar-event-handler {
        height: calc(100% - 44px - 24px - 2px);

        // Fixes: if chips row >2 => Create enough space between chips row & avoid first row chip touching the label
        // Related Issue: https://github.com/vuetifyjs/vuetify/issues/13107
        .select-guest {
            .v-select__selections {
                .v-chip--select {
                    margin-bottom: 6px;
                    margin-top: 6px;
                }
            }
        }
    }
</style>
