import axios from '@axios';

export default {
    namespaced: true,
    state: {
        calendarOptions: [
            {
                color: 'error',
                label: 'Pessoal',
            },
            {
                color: 'primary',
                label: 'Trabalho',
            },
            {
                color: 'warning',
                label: 'Familiar',
            },
            {
                color: 'success',
                label: 'Férias',
            },
            {
                color: 'info',
                label: 'ETC',
            },
        ],
        selectedCalendars: ['Pessoal', 'Trabalho', 'Familiar', 'Férias', 'ETC'],
    },
    getters: {},
    mutations: {
        SET_SELECTED_CALENDARS(state, val) {
            state.selectedCalendars = val;
        },
    },
    actions: {
        fetchEvents({ state }) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/apps/calendar/events', {
                        params: {
                            calendars: state.selectedCalendars.join(','),
                        },
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/apps/calendar/events', { event })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updateEvent(ctx, { event }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/apps/calendar/events/${event.id}`, { event })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        removeEvent(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/apps/calendar/events/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
