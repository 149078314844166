<template>
    <v-row justify="center">
        <v-dialog v-model="isActiveDialog" max-width="600" @click:outside="closeOnOverlay">
            <v-form v-model="isValid" ref="form" @submit.prevent="handleSubmit">
                <v-card>
                    <v-card-title class="text-h5"> Notificação Personalizada </v-card-title>

                    <v-card-text>
                        <div class="d-flex">
                            <v-select
                                v-model="notificationMethod"
                                item-text="label"
                                item-value="value"
                                :items="notificationMethodArray"
                                hide-details="auto"
                                :menu-props="{ offsetY: true }"
                                dense
                                outlined>
                            </v-select>
                            <v-text-field
                                class="mx-2 test"
                                dense
                                outlined
                                v-model="notificationTimeBefore"
                                hide-details="auto"
                                type="number"
                                :rules="[rules.required, rules.verifyValue]" />
                            <v-select
                                v-model="notificationTime"
                                item-text="label"
                                item-value="value"
                                :items="notificationTimeArray"
                                hide-details="auto"
                                :menu-props="{ offsetY: true }"
                                dense
                                outlined>
                            </v-select>
                        </div>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn color="error darken-1" text> Cancelar </v-btn>

                        <v-btn color="primary darken-1" type="submit" text> Concluído </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        props: ['isActive'],

        data() {
            return {
                isActiveDialog: null,
                notificationMethodArray: [
                    { value: 'notification', label: 'Notificação' },
                    { value: 'email', label: 'Email' },
                ],
                notificationTimeArray: [
                    { value: 'minutes', label: 'Minutos' },
                    { value: 'hours', label: 'Horas' },
                    { value: 'days', label: 'Dias' },
                    { value: 'weeks', label: 'Semanas' },
                ],

                notificationMethod: 'notification',
                notificationTime: 'minutes',
                notificationTimeBefore: '10',
                isValid: false,

                rules: {
                    required: (value) => !!value || 'Obrigatório.',
                    verifyValue: (value) => {
                        if (this.notificationTime == 'minutes') {
                            if (value < 0 || value > 40320) {
                                console.log(value);

                                return false || 'Tem de ser entre 0 e 40,320 minutos';
                            }
                        } else if (this.notificationTime == 'hours') {
                            if (value < 0 || value > 672) {
                                console.log(value);

                                return false || 'Tem de ser entre 0 e 672 horas';
                            }
                        } else if (this.notificationTime == 'days') {
                            if (value < 1 || value > 28) {
                                console.log(value);

                                return false || 'Tem de ser entre 1 e 28 dias';
                            }
                        } else if (this.notificationTime == 'weeks') {
                            if (value < 0 || value > 4) {
                                console.log(value);

                                return false || 'Tem de ser entre 0 e 4 semanas';
                            }
                        }
                    },
                },
            };
        },

        methods: {
            closeOnOverlay() {
                this.$emit('closeDialog');
            },

            handleSubmit() {
                this.isValid = this.$refs.form.validate();
                console.log(this.$refs.form.validate());
                if (!this.isValid) {
                    alert('Complete the form first');
                    return;
                }

                alert("It's saved!");
            },
        },

        watch: {
            isActive: function (newVal, oldVal) {
                console.log(newVal);
                this.isActiveDialog = newVal;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .select {
        max-width: 60px;
    }
</style>
